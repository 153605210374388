<template>
  <section class="new_css">
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0"
        :span="24"
        class="organizetoolbar"
        style="padding-bottom: 0px"
      >
        <el-form
          :inline="true"
          ref="selectForm"
          :model="selectForm"
          class="select_form"
        >
          <el-form-item label="姓名:">
            <el-input type="text" v-model="selectForm.userName"></el-input>
          </el-form-item>
          <el-form-item label="发展党员阶段:">
            <el-select v-model="selectForm.phases" placeholder="发展党员阶段">
              <el-option label="申请入党人" value="申请入党人"></el-option>
              <el-option label="入党积极分子" value="入党积极分子"></el-option>
              <el-option label="发展对象" value="发展对象"></el-option>
              <el-option label="预备党员" value="预备党员"></el-option>
              <el-option label="公司内党员" value="公司内党员"></el-option>
              <el-option label="公司外党员" value="公司外党员"></el-option>
            </el-select>
          </el-form-item>
          <toolbar
            :buttonList="buttonList"
            @callFunction="callFunction"
            :buttonListmsg="buttonListmsg"
          ></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <!-- <el-dropdown>
  <el-button type="primary">
    筛选<i class="el-icon-arrow-down el-icon--right"></i>
  </el-button>
  <el-dropdown-menu slot="dropdown">
    <el-dropdown-item>黄金糕</el-dropdown-item>
    <el-dropdown-item>狮子头</el-dropdown-item>
    <el-dropdown-item>螺蛳粉</el-dropdown-item>
    <el-dropdown-item>双皮奶</el-dropdown-item>
    <el-dropdown-item>蚵仔煎</el-dropdown-item>
  </el-dropdown-menu>
</el-dropdown> -->
    <div class="calc-height">
      <el-table
        :data="users"
        highlight-current-row
        height="100%"
     
        @current-change="selectCurrentRow"
        @row-dblclick="handleShow"
        class="new_table"
      >
        <el-table-column prop="UserName" label="名称"> </el-table-column>
        <el-table-column
          label="身份证号"
          show-overflow-tooltip
          prop="IDCard"
        ></el-table-column>
        <el-table-column prop="Phases" label="发展党员阶段"> </el-table-column>
        <el-table-column
          label="入党申请时间"
          prop="PartyDate"
          :formatter="formatStartTime"
        ></el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, jumper,total"
      :page-count="total"
      :total="totaldata"
    >
    </el-pagination>
    <el-dialog
      :title="operation ? '编辑' : '添加'"
      :visible.sync="SakaryVisible"
      v-model="SakaryVisible"
      :close-on-click-modal="false"
      width="70%"
    >
      <el-form
        ref="SakaryForm"
        :model="SakaryForm"
        class="demo-form-inline"
        label-width="150px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="姓名:"
              prop="UserName"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input
                type="text"
                :disabled="true"
                v-model="SakaryForm.UserName"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="chooseUser"
                ></el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="公司:"
              prop="Factory"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input type="text" v-model="SakaryForm.Factory" disabled>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="部门:"
              prop="Dpt"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input type="text" v-model="SakaryForm.Dpt" disabled>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="岗位:"
              prop="Post"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input type="text" :disabled="true" v-model="SakaryForm.Post">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-show="operation">
            <el-form-item label="手机号:">
              <el-input
                type="text"
                :disabled="true"
                v-model="SakaryForm.MobilePhone"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-show="operation">
            <el-form-item label="身份证号:">
              <el-input
                type="text"
                :disabled="true"
                v-model="SakaryForm.IDCard"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="发展党员阶段:">
              <el-select
                v-model="SakaryForm.Phases"
                placeholder="发展党员阶段"
                style="width: 100%"
              >
                <el-option label="申请入党人" value="申请入党人"></el-option>
                <el-option
                  label="入党积极分子"
                  value="入党积极分子"
                ></el-option>
                <el-option label="发展对象" value="发展对象"></el-option>
                <el-option label="预备党员" value="预备党员"></el-option>
                <el-option label="公司内党员" value="公司内党员"></el-option>
                <el-option label="公司外党员" value="公司外党员"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="入党申请时间:"
              prop="PartyDate"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-date-picker
                type="date"
                v-model="SakaryForm.PartyDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="积极分子推荐时间:"
              prop="ActivistDate"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-date-picker
                type="date"
                v-model="SakaryForm.ActivistDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="谈话人:"
              prop="TalkPersonName"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input
                type="text"
                :disabled="true"
                v-model="SakaryForm.TalkPersonName"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="chooseParty('TalkPersonName')"
                ></el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="培养人一:"
              prop="TrainPerson1Name"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input
                type="text"
                :disabled="true"
                v-model="SakaryForm.TrainPerson1Name"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="chooseParty('TrainPerson1Name')"
                ></el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="培养人二:"
              prop="TrainPerson2Name"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input
                type="text"
                :disabled="true"
                v-model="SakaryForm.TrainPerson2Name"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="chooseParty('TrainPerson2Name')"
                ></el-button>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="确定发展对象时间:" prop="DevelopmentDate" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-date-picker type="date" v-model="SakaryForm.DevelopmentDate" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="入党介绍人:"
              prop="IntroducerName"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input
                type="text"
                :disabled="true"
                v-model="SakaryForm.IntroducerName"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="chooseParty('IntroducerName')"
                ></el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="集中培训时间:" prop="CentralizedDate">
              <el-date-picker
                type="date"
                v-model="SakaryForm.CentralizedDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="预备党员接收时间:"
              prop="PrepareDate"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-date-picker
                type="date"
                v-model="SakaryForm.PrepareDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="正式成为党员时间:"
              prop="OfficialDate"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-date-picker
                type="date"
                v-model="SakaryForm.OfficialDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="党员关系所在地:" prop="RelationLocation">
              <el-input type="text" v-model="SakaryForm.RelationLocation">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="党员档案存放地:" prop="SaveLocation">
              <el-input type="text" v-model="SakaryForm.SaveLocation">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="党员关系转入时间:" prop="RelationDate">
              <el-date-picker
                type="date"
                v-model="SakaryForm.RelationDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-show="operation">
            <el-form-item label="老带新经历:" prop="Experience">
              <el-input type="text" v-model="SakaryForm.Experience" disabled>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <upload-files
          :files="SakaryForm.FileArry"
          :key="key"
          action="/cyl/ftp/ftp/upload"
          :limit="20"
          @fun="dealFiles"
          :IsDisabled="operation1"
          :IsDel="operation1"
        ></upload-files>
        <el-row v-if="operation1">
          <el-col :span="24" style="text-align: center">
         
              <el-button type="primary" v-on:click="onSubmit('SakaryForm')"
                >提交</el-button
              >
          
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog
      title="党员信息"
      :visible.sync="InsuranceVisible"
      v-model="InsuranceVisible"
      :close-on-click-modal="false"
      width="80%"
    >
      <el-row>
        <el-col
          v-if="buttonList.length > 0"
          :span="24"
          class="organizetoolbar"
          style="padding-bottom: 0px"
        >
          <el-form :inline="true" ref="selectForm1" :model="selectForm1">
            <el-form-item label="名称:">
              <el-input
                v-model="selectForm1.userName"
                placeholder="名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证:">
              <el-input
                v-model="selectForm1.iDCard"
                placeholder="身份证"
              ></el-input>
            </el-form-item>
            <!-- 这个就是当前页面内，所有的btn列表 -->
            <el-form-item>
              <!-- 这里触发点击事件 -->

              <el-button
                type="primary"
                size="mini"
                icon="el-icon-search"
                @click="getInsuranceData()"
                >查询</el-button
              >
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                @click="handleInEdit()"
                >选择</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-table
        :data="InsuranceData"
        highlight-current-row
        border
        height="500"
        @current-change="selectCurrentRow1"
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column prop="UserName" label="名称"> </el-table-column>
        <el-table-column prop="Male" label="性别"> </el-table-column>
        <el-table-column prop="IDCard" label="身份证号"> </el-table-column>
        <el-table-column
          label="党员状态"
          prop="PoliticsStatus"
        ></el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination
        align="center"
        @current-change="handleCurrentChange1"
        :current-page="currentPage1"
        :page-size="pageSize1"
        layout="prev, pager, next, jumper,total"
        :page-count="total1"
        :total="totaldata1"
      >
      </el-pagination>
    </el-dialog>
    <el-dialog
     title="选择人员"
      :visible.sync="addUserVisible"
      v-model="addUserVisible"
      width="75%"
      center
      append-to-body
    >
      <UserChoose
        :data="choosedusers"
        :all="true"
        :single="false"
        @callback="usercallFunction"
      ></UserChoose>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import UserChoose from "../../components/UserChoose";
import {
  GetPartyList,
  AddParty,
  UpdateParty,
        GetPartyDetail,
        DelPartyDetail,
  GetUserPostDptOrg,
  GetUserInfoPartyList,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
import UploadFiles from "../../components/UploadFiles";
export default {
  components: { Toolbar, UserChoose, UploadFiles },
  data() {
    return {
      searchVal: "",
      selectForm: {
        userName: "",
        phases: "",
      },
      key: 0,
      selectForm1: {
        userName: "",
        iDCard: "",
      },
      InsuranceVisible: false,
      fileKey: "",
      loading: true,
      operation1: true,
      choosedusers: [],
      CertTypeLists: [],
      InsuranceData: [],
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      fileList: [],
      currentPage1: 1, // 当前页码
      total1: null, // 总条数
      totaldata1: null,
      pageSize1: 20, // 每页的数据条数
      page1: 1,
      InsuranVisible: false,
      SakaryVisible: false,
      buttonListmsg: "",
      users: [],
      operation: false, // true:新增, false:编辑
      buttonList: [],
      addUserVisible: false,
      choosePartyString: "",
      SakaryForm: {
        Post: "",
        Dpt: "",
        Factory: "",
        UserId: "",
        UserName: "",
        Phases: "",
        PartyDate: "",
        TalkPersonName: "",
        TalkPersonId: "",
        ActivistDate: "",
        TrainPerson1Name: "",
        TrainPerson1Id: "",
        TrainPerson2Name: "",
        TrainPerson2Id: "",
        DevelopmentDate: "",
        IntroducerName: "",
        IntroducerId: "",
        CentralizedDate: "",
        PrepareDate: "",
        OfficialDate: "",
        RelationLocation: "",
        SaveLocation: "",
        RelationDate: "",
        FileArry: [],
      },
      InsuranForm: {
        UserId: "",
        ReduceDate: Date(),
        ReduceReason: "",
        BaseSalary: "",
      },
      filters: {
        name: "",
      },
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    selectCurrentRow1(val) {
      this.currentRow1 = val;
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.page1 = val;
      this.getInsuranceData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    chooseUser() {
      this.addUserVisible = true;
    },
    chooseParty(val) {
      this.choosePartyString = val;
      this.getInsuranceData();
      this.InsuranceVisible = true;
    },
    usercallFunction(newdata) {
      if (newdata.length > 1) {
        this.$message({
          message: "不允许选择多人",
          type: "error",
        });
        return;
      }
      console.log(newdata);

      this.SakaryForm.UserId = newdata[0].Id;
      this.SakaryForm.UserName = newdata[0].UserNameClaim;
      GetUserPostDptOrg({ userId: newdata[0].Id }).then((res) => {
        if (res.data.success) {
          this.SakaryForm.Factory = res.data.response.OrganizationName;
          this.SakaryForm.Dpt = res.data.response.DepartmentName;
          this.SakaryForm.Post = res.data.response.PostName;
        } else {
          this.$message({
            message: "获取部门信息出错",
            type: "error",
            duration: 5000,
          });
        }
      });
      this.addUserVisible = false;
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData();
    },
    //获取列表
    getData() {
      let para = {
        page: this.page,
        size: this.pageSize,
        userName: this.selectForm.userName,
        phases: this.selectForm.phases,
      };
      console.log(para);
      GetPartyList(para).then((res) => {
        console.log(res);
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getInsuranceData() {
      let para = {
        page: this.page1,
        size: this.pageSize1,
        userName: this.selectForm1.userName,
        iDCard: this.selectForm1.iDCard,
      };
      GetUserInfoPartyList(para).then((res) => {
        console.log(res);
        this.total1 = res.data.response.pageCount;
        this.totaldata1 = res.data.response.dataCount;
        this.InsuranceData = res.data.response.data;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }

      GetPartyDetail({ id: row.Id }).then((res) => {
        console.log(res);
        this.SakaryForm = res.data.response;
        this.key += 1;
        this.SakaryVisible = true;
      });

      this.operation = true;
      this.operation1 = true;
    },
    handleShow() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }

      GetPartyDetail({ id: row.Id }).then((res) => {
        console.log(res);
        this.SakaryForm = res.data.response;
        this.key += 1;
        this.SakaryVisible = true;
      });

      this.operation = true;
      this.operation1 = false;
    },
    handleInEdit() {
      let row = this.currentRow1;
      if (!row) {
        this.$message({
          message: "请选择党员信息！",
          type: "error",
        });
        return;
      }
      console.log(row);
      switch (this.choosePartyString) {
        case "TalkPersonName":
          this.SakaryForm.TalkPersonName = row.UserName;
          this.SakaryForm.TalkPersonId = row.UserId;
          break;
        case "TrainPerson1Name":
          this.SakaryForm.TrainPerson1Name = row.UserName;
          this.SakaryForm.TrainPerson1Id = row.UserId;
          break;
        case "TrainPerson2Name":
          this.SakaryForm.TrainPerson2Name = row.UserName;
          this.SakaryForm.TrainPerson2Id = row.UserId;
          break;
        case "IntroducerName":
          this.SakaryForm.IntroducerName = row.UserName;
          this.SakaryForm.IntroducerId = row.UserId;
          break;
      }
      this.InsuranceVisible = false;
    },
    handleDel() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }

      //this.InsuranForm.UserId = row.UserId;
      //this.InsuranForm.BaseSalary = row.BaseSalary;
      //  this.InsuranVisible = true;
        let para = {
            id: row.Id,
        };
        this.$confirm("是否确定删除此数据？", "提示", {}).then(() => {
            DelPartyDetail(para).then((res) => {
                if (res.data.success) {
                    this.$message({
                        message: "删除成功",
                        type: "success",
                        duration: 5000,
                    });
                    this.SakaryVisible = false;
                    this.getData(false);
                } else {
                    this.$message({
                        message: "删除失败",
                        type: "error",
                        duration: 5000,
                    });
                }
            });
        });
        
    },
    handleAdd() {
      (this.SakaryForm = {
        Post: "",
        Dpt: "",
        Factory: "",
        UserId: "",
        UserName: "",
        Phases: "",
        PartyDate: "",
        TalkPersonName: "",
        TalkPersonId: "",
        ActivistDate: "",
        TrainPerson1Name: "",
        TrainPerson1Id: "",
        TrainPerson2Name: "",
        TrainPerson2Id: "",
        DevelopmentDate: "",
        IntroducerName: "",
        IntroducerId: "",
        CentralizedDate: "",
        PrepareDate: "",
        OfficialDate: "",
        RelationLocation: "",
        SaveLocation: "",
        RelationDate: "",
        FileArry: [],
      }),
        (this.key += 1);
      (this.operation = false), (this.operation1 = true);
      this.SakaryVisible = true;
    },

    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          let para = Object.assign({}, this.SakaryForm);
          console.log(para);
          if (!this.operation) {
            //新增
            AddParty(para).then((res) => {
              console.log(res);
              if (res.data.success) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 5000,
                });
                this.SakaryVisible = false;
                this.getData();
              } else {
                this.$message({
                  message: "添加失败",
                  type: "error",
                  duration: 5000,
                });
              }
            });
          } else {
            //修改
            UpdateParty(para).then((res) => {
              console.log(res);
              if (res.data.success) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                  duration: 5000,
                });
                this.SakaryVisible = false;
                this.getData(false);
              } else {
                this.$message({
                  message: "修改失败",
                  type: "error",
                  duration: 5000,
                });
              }
            });
          }
        });
      });
    },
    dealFiles(data) {
      console.log(data.backData);
      this.SakaryForm.FileArry = data.backData;
      // this.fileList = data.backData;
      // console.log('dealFiles-list:' + this.fileList);
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>

